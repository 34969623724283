import styled from 'styled-components'
import CustomLink from 'components/CustomLink'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'

// Paragraph
export const Paragraph = styled.p`
  margin: 0;
`

export const A = styled.a``

export const FirstHeader = styled.h1`
  margin: 0;
`

export const SecondHeader = styled.h2`
  margin: 0;
  ${props => props.opacity && `opacity: ${props.opacity};`}
  ${props => props.width && `width: ${props.width}%;`}
`

export const BlueStripe = styled.div`
  height: 5px;
  ${props => props.width ? `width: ${props.width}px;` : `width: 91px;`}
`

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  border-radius: 3px;
`

export const StyledCustomLink = styled(CustomLink)`
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 12px;
  padding-top: 12px;
  border-radius: 3px;
  transition: 0.25s;

  ${props => props.hover && `
    :hover {
      transform: scale(1.05);
    }
  `}
`

export const Content = styled.div`
  font-size: ${(props) => props.theme.font.size.m}px;

  p {
    padding-bottom: 25px;
  }

  h3 {
    font-size: ${(props) => props.theme.font.size.m}px;
    font-weight: ${(props) => props.theme.font.weight.xl};
    margin: 0;
  }
`

export const Image = styled.img`
  ${props => props.height && `min-height: ${props.height}px !important;`}
  ${props => props.width && `min-width: ${props.width}px !important;`}
`

export const Button = styled.button`
  ${props => props.height && `height: ${props.height}px;`}
  ${props => props.width && `width: ${props.width}px;`}
`

export const Ul = styled.ul`
  margin-left: -20px;
  justify-content: space-evenly;
`

export const Li = styled.li`
  list-style: none;
  transition: 0.25s;
  cursor: pointer;
  white-space: nowrap;

  img {
    margin-top: -5px;
  }

  &.menubtn {
    @media (min-width: 992px){
    background-color: ${(props) => props.theme.color.contrast};
    height: 44px;
    border-radius: 59px;
    padding: 8px 24px !important;
    margin-left: 50px;
    border: 1px solid ${(props) => props.theme.color.contrast};

    :hover {
      background-color: ${(props) => props.theme.color.main};
    }

    a {
      border: none !important;
      color: #fff;
    }
  }
  }
`

export const StyledBackgroundImage = styled(BackgroundImage)`
  z-index: -20;
  height: 100%;
`

export const SingleGradient = styled.div`
  height: 100%;
  z-index: -15;
  background: rgb(39,39,39);
  background: linear-gradient(87deg, rgba(39,39,39,0.8995973389355743) 30%, rgba(0,0,0,0) 80%);
  ${props => props.height && `height: ${props.height}px;`}
`

export const CTA = styled.button`
  justify-content: space-evenly;
  padding-left: 20px;
  padding-right: 20px;
  width: 210px;
  
  ${Image} {
    height: 24px;
    width: 31px;
    margin-right: 12px;
  };
`
