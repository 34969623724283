import React from 'react'

// Components
import CustomLink from 'components/CustomLink'

// Third Party
import parse from 'html-react-parser'

const Content = ({ content, className }) => (
  <div className={className}>
    {parse(content, {
      replace: (domNode) => {
        if (domNode.name === 'a') {
          if (domNode.attribs.href.indexOf('www.') !== -1) {
            // Dont replace
          } else {
            return <CustomLink to={domNode.attribs.href}>{domNode.children[0].data}</CustomLink>
          }
        }
        return true
      }
    })}
  </div>
)

export default Content
