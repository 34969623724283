/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import { SecondHeader, Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'
import SnelLogo from 'img/logo.inline.svg'
import Content from 'components/Content'

// Third Party
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const FooterWrapper = styled.div``

const FooterHeader = styled.div`
  min-height: 61px;
`

const FooterContent = styled.div`
  line-height: 1.81;
`

const StyledCustomLink = styled(CustomLink)`
  ${Paragraph} {
    transition: 0.15s;
  }

  :hover {
    ${Paragraph} {
      color: ${(props) => props.theme.color.text.striking};
    }
  }
`

const SocialCustomLink = styled(CustomLink)`
  ${Paragraph} {
    line-height: normal;
    text-decoration: underline;
  }
`

const Separator = styled.div`
  height: 1px;
`

const Footer = ({ waardeIsHidden, setWaardeHidden }) => {
  const {
    footer: {
      footer: {
        information,
        aanbodLabel,
        aanbod,
        dienstenLabel,
        diensten,
        overOnsLabel,
        overOns,
        merken,
        socials
      }
    }
  } = useStaticQuery(graphql`{
    footer: wpComponent(databaseId: {eq: 9}) {
      footer {
        information {
          logo {
            localFile {
              publicURL
            }
          }
          locatiesfooter
          phonenumber
          email
        }
        aanbodLabel
        aanbod {
          link {
            target
            url
            title
          }
        }
        dienstenLabel
        diensten {
          link {
            target
            title
            url
          }
        }
        overOnsLabel
        overOns {
          link {
            target
            title
            url
          }
        }
        nieuwsbriefLabel
        merken {
          funda {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              target
              title
              url
            }
          }
          nvm {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              target
              title
              url
            }
          }
        }
        socials {
          icon {
            localFile {
              publicURL
            }
          }
          link {
            target
            title
            url
          }
        }
      }
    }
  }`)

  return (
    <>
      <FooterWrapper className="footer pt-5 color-background-secondary">
        <div className="container">
          <div className="row pb-3 pb-lg-5">
            <div className="col-12 col-lg-6 pb-4 pb-lg-0">
              <FooterHeader className="d-flex align-items-end mb-5">
                <img src={SnelLogo} alt="" width="200" />
              </FooterHeader>
              <FooterContent className="d-flex flex-wrap flex-lg-nowrap">
                <div className="col-5 px-0">
                  <Content content={information.locatiesfooter} />
                </div>
                <div className="col-5 px-0">
                  <SecondHeader className="font-size-18 font-weight-xl mb-2">Contact</SecondHeader>
                  <StyledCustomLink external to={`mailto:${information.email}`}>
                    <Paragraph>{information.email}</Paragraph>
                  </StyledCustomLink>
                  <StyledCustomLink external to={`tel:${information.phonenumber}`}>
                    <Paragraph>{information.phonenumber}</Paragraph>
                  </StyledCustomLink>
                </div>
              </FooterContent>
            </div>
            <div className="col-12 col-lg-2 pb-4 pb-lg-0">
              <Separator className="color-background-main d-lg-none d-block" />
              <FooterHeader className="d-flex align-items-end pb-2 mb-2">
                <SecondHeader className="font-size-m font-weight-xl">{aanbodLabel}</SecondHeader>
              </FooterHeader>
              <FooterContent>
                {aanbod.map((item, index) => (
                  <StyledCustomLink key={index} to={item.link.url}>
                    <Paragraph>{item.link.title}</Paragraph>
                  </StyledCustomLink>
                ))}
              </FooterContent>
            </div>
            <div className="col-12 col-lg-2">
              <FooterHeader className="d-flex align-items-end pb-2 mb-2">
                <SecondHeader className="font-size-m font-weight-xl">{dienstenLabel}</SecondHeader>
              </FooterHeader>
              <FooterContent>
                {diensten.map((item, index) => (
                  <StyledCustomLink key={index} to={item.link.url}>
                    <Paragraph>{item.link.title}</Paragraph>
                  </StyledCustomLink>
                ))}
              </FooterContent>
            </div>
            <div className="col-12 col-lg-2 pb-4 pb-lg-0">
              <FooterHeader className="d-flex align-items-end pb-2 mb-2">
                <SecondHeader className="font-size-m font-weight-xl">{overOnsLabel}</SecondHeader>
              </FooterHeader>
              <FooterContent>
                {overOns.map((item, index) => (
                  <StyledCustomLink key={index} to={item.link.url}>
                    <Paragraph>{item.link.title}</Paragraph>
                  </StyledCustomLink>
                ))}
              </FooterContent>
            </div>
          </div>
        </div>
        <div className="color-background-main">
          <div className="container">
            <div className="row py-3 justify-content-between">
              <div className="col-6 d-flex align-items-center">
                {socials.map((social, index) => (
                  <SocialCustomLink newPage className="pr-2" external key={index} to={social.link.url}>
                    <img src={social.icon.localFile.publicURL} className="pr-2" alt="" />
                  </SocialCustomLink>
                ))}
              </div>
              <div className="col-6 d-flex align-items-center justify-content-end">
                <SocialCustomLink newPage to={merken.nvm.link.url} external className="mr-lg-4">
                  <img src={merken.nvm.icon.localFile.publicURL} alt="" />
                </SocialCustomLink>
                <SocialCustomLink newPage to={merken.funda.link.url} external>
                  <img src={merken.funda.icon.localFile.publicURL} alt="" />
                </SocialCustomLink>
              </div>
            </div>
          </div>
        </div>
      </FooterWrapper>
      {/* <WaardePopUp isHidden={waardeIsHidden} setHidden={setWaardeHidden} /> */}
    </>
  )
}

export default Footer
