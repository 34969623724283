import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

export const mainTheme = {
  color: {
    main: '#242944',
    contrast: '#812a37',
    secondary: '#F3F3F3',
    striking: '#812a37',
    green: '#23CF53',
    light: '#FFFFFF',
    lightGrey: '#eff0f6',

    text: {
      main: '#242944',
      contrast: '#812a37',
      striking: '#812a37',
      light: '#FFFFFF',
      green: '#23CF53',
    },
  },

  font: {
    family: {
      main: 'Neulis Alt',
      secondary: 'Open Sans',
    },

    size: {
      xxs: '8px',
      xs: '10px',
      s: '12px',
      sm: '14px',
      m: '16px',
      xm: '18px',
      ml: '22px',
      l: '24px',
      xl: '32px',
      xxl: '36px',
      xxml: '45px',
      xxxl: '75px',
    },

    weight: {
      xs: 200,
      s: 300,
      sm: 400,
      m: 500,
      l: 600,
      xl: 900,
    },
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props) => props.theme.font.family.main} !important;
    font-size: ${(props) => props.theme.font.size.m} !important;
    font-weight: ${(props) => props.theme.font.weight.sm} !important;
    color: ${(props) => props.theme.color.text.main} !important;
  }
  
  .color {
    &-background {
      &-main { background-color: ${(props) => props.theme.color.main} }
      &-contrast { background-color: ${(props) => props.theme.color.contrast} }
      &-secondary { background-color: ${(props) =>
        props.theme.color.secondary} }
      &-alt { background-color: ${(props) => props.theme.color.alt} }
      &-green { background-color: ${(props) => props.theme.color.green} }
      &-grey { background-color: ${(props) => props.theme.color.grey} }
      &-lightGrey { background-color: ${(props) =>
        props.theme.color.lightGrey} }
      &-light { background-color: ${(props) => props.theme.color.light} }
      &-striking { background-color: ${(props) => props.theme.color.striking} }
    }
    
    &-text {
      &-main { color: ${(props) => props.theme.color.text.main} }
      &-contrast { color: ${(props) => props.theme.color.text.contrast} }
      &-light { color: ${(props) => props.theme.color.text.light} !important; }
      &-striking { color: ${(props) => props.theme.color.text.striking} }
    }
  }
  
  .font {
    &-family {
      &-main { font-family: ${(props) => props.theme.font.family.main} }
      &-secondary { font-family: ${(props) =>
        props.theme.font.family.secondary} }
    }
    
    &-size {
      &-xxs { font-size: ${(props) => props.theme.font.size.xxs} }
      &-xs { font-size: ${(props) => props.theme.font.size.xs} }
      &-s { font-size: ${(props) => props.theme.font.size.s} }
      &-sm { font-size: ${(props) => props.theme.font.size.sm} }
      &-m { font-size: ${(props) => props.theme.font.size.m} }
      &-xm { font-size: ${(props) => props.theme.font.size.xm} }
      &-ml { font-size: ${(props) => props.theme.font.size.ml} }
      &-l { font-size: ${(props) => props.theme.font.size.l} }
      &-xl { font-size: ${(props) => props.theme.font.size.xl} }
      &-xxl { font-size: ${(props) => props.theme.font.size.xxl} }
      &-xxxl { font-size: ${(props) => props.theme.font.size.xxxl} }
    }
    
    &-weight {
      &-xs { font-weight: ${(props) => props.theme.font.weight.xs} }
      &-s { font-weight: ${(props) => props.theme.font.weight.s} }
      &-m { font-weight: ${(props) => props.theme.font.weight.m} }
      &-l { font-weight: ${(props) => props.theme.font.weight.l} }
      &-xl { font-weight: ${(props) => props.theme.font.weight.xl} }
    }
  }

  h1 {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.xxl};
    font-weight: ${(props) => props.theme.font.weight.l};
  }

  h2 {
    font-family: ${(props) => props.theme.font.family.main} ;
    font-size: ${(props) => props.theme.font.size.xl};
    font-weight: ${(props) => props.theme.font.weight.l};
    color: ${(props) => props.theme.color.text.main};
  }

  h3 {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.l};
  }

  .line-height-none {
    line-height: 1;
  }

  a {
    color: ${(props) => props.theme.color.text.main};
  }
  
  .line-height-m {
    line-height: 1.5;
  }

  @media (min-width: 1200px) {
    .gf_left_half, .gf_right_half {
      width: calc(50% - 7.5px);
      display: inline-block;
    }
    
    .gf_left_half {
      margin-right: 7.5px;
    }
    
    .gf_right_half {
      margin-left: 7.5px;
    }
  }

`

function Theme({ children }) {
  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Theme
