/* eslint-disable import/order */
import React, { useState } from 'react'
import styled from 'styled-components'

// Third Party
import Helmet from 'react-helmet'

import Theme from '../styles/theme'

// Images
import Whatsapp from '../img/whatsapp-snel-era.svg'

// Components
import Header from './Header'
import Footer from './Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/all.css'
import '../utils/fonts/stylesheet.css'

const WhatsappDiv = styled.div`
  right: 30px;
  bottom: 20px;
  z-index: 999;
  width: 64px;
  height: 60px;

  @media (max-width: 991px) {
    right: 10px;
  }
`

const Layout = ({ children }) => {
  const [waardeIsHidden, setWaardeHidden] = useState(false)

  return (
    <Theme>
      <Header />
      <>{children}</>
      <Footer
        waardeIsHidden={waardeIsHidden}
        setWaardeHidden={setWaardeHidden}
      />
      <WhatsappDiv className="position-fixed">
        <a
          href="https://wa.me/+31334947720?text=Ik%20heb%20een%20vraag"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img src={Whatsapp} alt="Whatsapp ons" width="60" />
        </a>
      </WhatsappDiv>
      <Helmet>
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="356b3042-df95-49c1-ba24-72f7e34ee34c"
          type="text/javascript"
          async
        />
        <script type="text/javascript">
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:2345509,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
    </Theme>
  )
}

export default Layout
